/**
 * SearchByListngPageMobile
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchResult, selectLocalization } from 'store/modules/model';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { buildQueryString, debounce } from 'utils/helper-utils';
import { ButtonVariant, NumberRange } from 'pages/sharedModelTypes';

import Button from 'components/Button';
import Pills from 'components/Pills';
import FilterModal from 'components/FilterModal';
import AccordionFilter from 'components/AccordionFilter';
import Autocomplete from 'components/Autocomplete';
import { PillItem } from 'components/Pills/Pills';
import { IconCross } from 'components/Boilerplate/Icon/IconCollection';
import {
	FacetModel,
	FacetOption,
	FacetTypeOf,
	SearchListingModel,
} from 'components/SearchByListngPage/SearchByListngPage.model';
import {
	AllFilterButton,
	PillResetButton,
	PillsBlock,
	ResultMessageHelper,
	SearchInputWrapper,
	SearchOverlappBlock,
	StyledSpan,
} from 'components/SearchByListngPage/SearchByListngPage.styles';
import { getEpiSearchResult } from 'api/epiApi';

interface UpdatedFacets {
	facets: {
		[key: string]: string[];
	};
}

interface FacetsTypes {
	[key: string]: string[];
}

export type queryParameters = {
	id: string;
	p: number | null;
	s: number;
	lang: string;
	q?: string;
	facets: FacetsTypes;
	sortBy: string | null;
};

interface Props {
	/** Description of this property */
	filterLabel: string;
	searchActionText: string;
	searchTopic: string;
	searchResultLabel: string;
	searchPlaceholder: string | null;
	searchResultLabelNoMatchesHint: string;
	searchResultLabelNoMatches: string;

	searchModel: SearchListingModel;
	url?: string;
	rootPageId: string;
	clearAllFiltersText: string;
	allFilterText: string;
	pageChanged?: boolean;
	modelType: string;
	searchApi: string;
	autoCompleteApi: string;
	/**use this flag to hide/show clear all link */
	showClearAll?: boolean;
	/**use this flag to hide/show no result feedback under search field */
	filterEnabled?: boolean;
}

/** Main description for this component. */
const SearchByListngPageMobile: React.FC<Props> = ({
	filterLabel,
	searchActionText,
	searchTopic,
	searchResultLabel,
	searchPlaceholder,
	searchResultLabelNoMatchesHint,
	searchResultLabelNoMatches,
	searchModel,
	url,
	rootPageId,
	pageChanged = false,
	clearAllFiltersText,
	allFilterText,
	modelType,
	searchApi,
	autoCompleteApi,
	showClearAll = false,
	filterEnabled = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const localization = useSelector(selectLocalization);
	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const searchRef = useRef<HTMLInputElement | null>(null);
	const [userQuery, setUserQuery] = useState<string>('');
	const [pillItem, setPillItems] = useState<PillItem[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [showAutocomplete, setShowAutocomplete] = useState(true);
	/**filter exist flag to disbale or enable clear button in mobile filter modal */
	const [filterExist, setFilterExist] = useState<boolean>(false);

	// destrcture seachModel
	const { query, page, size, facetModels, sortModel } = searchModel;

	/**variable to keep track of filtered items count in mobile**/
	const [
		mobileFilteredItemsCount,
		setMobileFilteredItemsCount,
	] = useState<number>(searchModel?.numberOfHits);

	/**variable to keep track of year filter items count in mobile**/
	const [mobileYearItemsCount, setMmobileYearItemsCount] = useState<number>(0);
	/**variables to track changed facet and sort data in mobile */
	const [stageFacetModels, setstageFacetModels] = useState<FacetModel[]>(
		facetModels
	);

	/**saving selected date to pass in to the date selector component for mobile - used to assign date when there is only one year is selected */
	const [selectedDates, setSelectedDates] = useState<NumberRange>({
		from: null,
		to: null,
	});

	const openModal = () => {
		setShowModal(true);
		document.body.classList.add('modal-open');
	};

	const closeModal = () => {
		setShowModal(false);
		document.body.classList.remove('modal-open');
	};

	const getInitialParameters = (): queryParameters => {
		return {
			q: query ?? '',
			p: page,
			s: size,
			id: rootPageId,
			lang: localization ? localization?.culture : 'sv',
			facets: {},
			sortBy: null,
		};
	};
	const [searchState, setSearchState] = useState<queryParameters>(
		getInitialParameters()
	);

	useEffect(() => {
		handleURLParameters(history.location);
	}, []);

	useEffect(() => {
		setMobileFilteredItemsCount(searchModel?.numberOfHits);
	}, [searchModel?.numberOfHits]);

	useEffect(() => {
		const handlePopState = (event: any) => {
			const newSearchState = {
				...searchState,
				facets: {},
				q: '',
				sortBy: null,
				p: 1,
			};
			const currentURL = window.location.pathname;
			setSearchState(newSearchState);
			setPillItems([]);
			executeSearch(newSearchState, currentURL);
		};

		window.addEventListener('popstate', handlePopState);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [history]);

	useEffect(() => {
		if (pageChanged) {
			const newSearchState = {
				...searchState,
				p: searchModel.page,
			};
			executeSearch(newSearchState);
		}
	}, [pageChanged]);

	useEffect(() => {
		if (showClearAll) {
			resetAll();
		}
	}, [showClearAll]);

	useEffect(() => {
		setstageFacetModels(facetModels);
		getMobilePill();
	}, [facetModels]);

	/** filter flag that is used to disable or enable clear all button in mobile fitler*/
	useEffect(() => {
		let filterFlag = Object.values(searchState?.facets).some(
			(facet) => facet.length > 0
		);
		setFilterExist(filterFlag);
	}, [searchState]);
	/** update date select pills */
	const updatedDateSelectPills = (
		TypeFilter: string,
		facetOptions: FacetOption[]
	) => {
		facetOptions.forEach((option) => {
			if (option.key === 'selectableYears') {
				const { timeInterval } = option;
				const selectedYears = timeInterval?.selectableYears.filter(
					(year) => year.selected
				);

				if (selectedYears === undefined) return;
				if (selectedYears && selectedYears.length > 1) {
					const kPLabel = selectedYears[0]?.year.toString();
					const minYear = Math.min(...selectedYears.map((year) => year.year));
					const maxYear = Math.max(...selectedYears.map((year) => year.year));

					// Create a single pill with the combined years
					setPillItems((prevPillItems) => [
						...prevPillItems,
						{
							label: `${minYear}-${maxYear}`,
							TypeFilter,
							keyPill: `${minYear}-${maxYear}`,
						},
					]);
				} else if (selectedYears && selectedYears.length === 1) {
					const kPLabel = selectedYears[0]?.year.toString();
					let dateLabel: string = '';
					let facets = getFacetsFromUrl();
					facets &&
						facets['ReleaseDate']?.map((item: string, index: number) => {
							if (option && option.timeInterval) {
								const fromLabel = `${
									option.timeInterval.fromLabel ?? 'Från och med'
								} ${kPLabel}`;
								const toLabel = `${
									option.timeInterval.toLabel ?? 'Till och med'
								} ${kPLabel}`;

								dateLabel = item.startsWith('F')
									? fromLabel
									: item.startsWith('T')
									? toLabel
									: '';
							}
						});

					// Create a pill with that single year
					setPillItems((prevPillItems) => [
						...prevPillItems,
						{
							label: dateLabel,
							TypeFilter,
							keyPill: kPLabel,
						},
					]);
				}
			} else {
				const { name: optionName, selected, key: keyPill } = option;

				if (selected) {
					setPillItems((prevPillItems) => [
						...prevPillItems,
						{
							label: optionName,
							TypeFilter,
							keyPill: keyPill,
						},
					]);
				}
			}
		});
	};
	/* we update pills when user click on visa button in mobile view with selected filter options */
	const getMobilePill = () => {
		setPillItems([]);

		if (facetModels) {
			facetModels.map((item: FacetModel) => {
				const TypeFilter = item.key;
				if (item.facetType === 'bool') {
					return;
				}
				if (item.facetType === 'dateSelect') {
					updatedDateSelectPills(TypeFilter, item.facetOptions);
				} else {
					item.facetOptions.map((option: any, i: number) => {
						if (option.selected) {
							setPillItems((prevPillItems) => [
								...prevPillItems,
								{
									label: option.name,
									TypeFilter,
									keyPill: option.key,
								},
							]);
						}
					});
				}
			});
		}
	};

	const onPillRemove = (pill: PillItem, flag: boolean) => {
		const updatePills = pillItem.filter(
			(item) => item.keyPill !== pill.keyPill
		);
		const updatedFacets: UpdatedFacets = { facets: { ...searchState.facets } };
		if (pill) {
			if (updatedFacets.facets.hasOwnProperty(pill.TypeFilter)) {
				if (pill.TypeFilter === 'ReleaseDate') {
					// Remove 'F' and 'T' from the string and update the value in updatedFacets
					updatedFacets.facets[pill.TypeFilter] = updatedFacets.facets[
						pill.TypeFilter
					].map((date) => date.replace('F', '').replace('T', ''));
				}

				if (updatedFacets.facets[pill.TypeFilter].includes(pill.keyPill)) {
					updatedFacets.facets[pill.TypeFilter] = updatedFacets.facets[
						pill.TypeFilter
					].filter((opt) => opt !== pill.keyPill);
				}
			}
		}
		const newSearchState = {
			...searchState,
			...updatedFacets,
		};
		setPillItems(updatePills);
		setSearchState(newSearchState);
		// flag tell if make request update or not. If it comes from onFilterChanged no need
		if (flag) {
			debouncedExecuteSearch(newSearchState, '');
		}
	};

	const handleURLParameters = (location: any) => {
		if (location.search.length < 0) {
			return;
		}
		// Decode URL parameters
		const urlParams = new URLSearchParams(location.search);
		const facets = urlParams.get('facets');
		const query = urlParams.get('q');
		const sortBy = urlParams.get('sortBy');
		const page = urlParams.get('p');

		const p = page ? parseInt(page, 10) : 1;

		// Split facets
		let splitedFacets = facets?.split(';') || [];
		let ff: { [key: string]: string[] } = {};

		splitedFacets.forEach((facet) => {
			const [key, ...values] = facet.split(':');
			const decodedValues = values.map((value) => decodeURIComponent(value));

			if (!ff[key]) {
				ff[key] = [];
			}
			ff[key] = ff[key].concat(decodedValues);
		});

		// Update search bar if query exists
		setUserQuery(query ?? '');

		// Update newSearchState with URL params
		const newSearchState = {
			...searchState,
			facets: ff,
			q: query ?? '',
			sortBy: sortBy ?? searchState.sortBy,
			p: p,
		};
		setSearchState(newSearchState);

		// need compare if newSearchState is same as const { query, page, size, facetModels } = searchModel;
		if (
			newSearchState.q !== searchModel.query ||
			newSearchState.p !== searchModel.page
		) {
			executeSearch(newSearchState);
		} else {
			const keys = Object.keys(newSearchState.facets);

			// contemplate facetModels
			searchModel.facetModels.forEach((facetModel) => {
				const facetKey = facetModel.key;
				if (keys.includes(facetKey)) {
					facetModel.facetOptions.forEach((facetOption) => {
						if (
							!facetOption.selected &&
							facetOption.key === newSearchState.facets[facetKey]?.[0]
						) {
							executeSearch(newSearchState);
						}
					});
				}
			});
		}
	};

	/* search actions */

	const handleUserQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserQuery(event.target.value);
		setShowAutocomplete(true);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (userQuery.length > 0) {
			const params = {
				...searchState,
				q: userQuery,
				p: 1,
			};
			setSearchState(params);
			executeSearch(params);
		}
	};

	const resetSearch = () => {
		setUserQuery('');

		if (searchState.q) {
			const params = {
				...searchState,
				q: '',
				p: searchState.p ?? 1,
			};
			setSearchState(params);
			executeSearch(params);
		}
	};

	/* filters actions */

	const resetFilters = () => {
		setPillItems([]);
		searchModel && setMobileFilteredItemsCount(searchModel?.numberOfHits);
		const newSearchState = {
			...searchState,
			facets: {},
			sortBy: null,
		};

		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const resetAll = () => {
		setPillItems([]);
		setUserQuery('');
		const newSearchState = {
			...searchState,
			facets: {},
			sortBy: null,
			q: '',
			p: 1,
		};
		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	/** in mobile view we dont fetch data from backend unless user click on visa button - isntead we keep update filter state client side */
	const onMobileFilterChanged = (filter: any, typeOfFacet: FacetTypeOf) => {
		const target = filter.target;

		const filterTypeOf = target.getAttribute('data-filter-typeof');
		const checkboxValue = target.value.trim();
		const updatedFacets: UpdatedFacets = { facets: { ...searchState.facets } };

		// Check if the key exists
		if (updatedFacets.facets.hasOwnProperty(filterTypeOf)) {
			if (updatedFacets.facets[filterTypeOf].includes(checkboxValue)) {
				// if true > user click unchecked existing opt & remove pill
				updatedFacets.facets[filterTypeOf] = updatedFacets.facets[
					filterTypeOf
				].filter((opt) => opt !== checkboxValue);
			} else {
				// add the opt to arr & add pill
				if (typeOfFacet === FacetTypeOf.multiSelect) {
					updatedFacets.facets[filterTypeOf] = [
						...updatedFacets.facets[filterTypeOf],
						checkboxValue,
					];
				} else {
					updatedFacets.facets[filterTypeOf] = [checkboxValue];
				}
			}
		} else {
			// if not exist i create it
			updatedFacets.facets[filterTypeOf] = [checkboxValue];
		}

		/**below we update stage facet, sort object and total filtered count with updates  */

		// Clone the current facets model to update it
		const updatedFacetModels = stageFacetModels.map((facet: FacetModel) => ({
			...facet,
			facetOptions: facet.facetOptions.map((option: FacetOption) => ({
				...option,
				selected:
					facet.key === filterTypeOf && option.key === checkboxValue
						? target.checked
						: option.selected,
			})),
		}));
		setstageFacetModels(updatedFacetModels);

		const newSearchState = {
			...searchState,
			...updatedFacets,
			p: 1,
		};
		setSearchState(newSearchState);

		/** logic to calculate the total hits and checking if there are multiple facet type got checked  */
		let totalFilteredHits = 0;
		let selectedFacetModelsCount = 0;

		for (const facet of updatedFacetModels) {
			let hasSelectedOption = false;

			if (facet.key === 'ReleaseDate' && facet.facetType === 'dateSelect') {
				// Handle date facetOptions
				for (const option of facet.facetOptions) {
					if (option.timeInterval) {
						// Check if any of the selectable years are selected
						for (const yearOption of option.timeInterval.selectableYears) {
							if (yearOption.selected) {
								hasSelectedOption = true;
								break; // Since only one year can be selected, break after finding the first selected one
							}
						}
					} else if (
						updatedFacets.facets[facet.key] &&
						updatedFacets.facets[facet.key].includes(option.key) &&
						option.selected
					) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true;
						break; // Only one option can be selected, so break after finding the first selected one
					}
				}
			} else if (facet.key === 'IsOfficialStatistics') {
				// Handle other regular facetOptions
				for (const option of facet.facetOptions) {
					if (updatedFacets.facets[facet.key] && option.selected) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true; // Marking  this facet model has a selected option
					}
				}
			} else {
				// Handle other regular facetOptions
				for (const option of facet.facetOptions) {
					if (
						updatedFacets.facets[facet.key] &&
						updatedFacets.facets[facet.key].includes(option.key) &&
						option.selected
					) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true; // Marking  this facet model has a selected option
					}
				}
			}

			if (hasSelectedOption) {
				selectedFacetModelsCount++; // Increment the count if any option in this facet is selected
			}
		}

		// If more than one facet model has selected options, we will call the api to get hits by passing "hitsonly" value true
		if (selectedFacetModelsCount > 1) {
			executeSearch(newSearchState, '', true);
		} else {
			// use normal calulcated hit count
			setMobileFilteredItemsCount(
				selectedFacetModelsCount == 0
					? searchModel?.numberOfHits
					: totalFilteredHits + mobileYearItemsCount
			);
		}
	};

	const onDateSelectChanged = (range: NumberRange, filterTypeOf: string) => {
		let totalYearHits = 0;
		const updatedFacets: any = {
			facets: { ...searchState.facets },
		};
		//updating stagefacet models
		const updatedFacetModels = stageFacetModels.map((facet: FacetModel) => {
			if (facet.key === 'ReleaseDate') {
				return {
					...facet,
					facetOptions: facet.facetOptions.map((option: FacetOption) => {
						if (option.key === 'selectableYears' && option.timeInterval) {
							return {
								...option,
								timeInterval: {
									...option.timeInterval,
									selectableYears: option.timeInterval.selectableYears.map(
										(yearObj) => {
											// Determine if the year falls within the range based on the provided `from` and `to`
											const withinRange =
												range.from && range.to
													? yearObj.year >= range.from &&
													  yearObj.year <= range.to
													: range.from
													? yearObj.year >= range.from
													: range.to
													? yearObj.year <= range.to
													: false;

											// Add up hits for all years within the determined range
											if (withinRange) {
												totalYearHits += yearObj.hits;
											}

											return {
												...yearObj,
												selected:
													yearObj.year === range.from ||
													yearObj.year === range.to,
											};
										}
									),
								},
							};
						} else {
							return {
								...option,
								selected: false,
							};
						}
					}),
				};
			}
			return facet;
		});
		setstageFacetModels(updatedFacetModels);
		setMmobileYearItemsCount(totalYearHits);

		setSelectedDates(range);

		//updating search state

		const startYear = 'F';
		const endYear = 'T';

		// Build the facet string
		if (range.from && range.to) {
			updatedFacets.facets[filterTypeOf] = [
				startYear + range.from + '-' + endYear + range.to,
			];
		} else if (range.from) {
			updatedFacets.facets[filterTypeOf] = [startYear + range.from];
		} else if (range.to) {
			updatedFacets.facets[filterTypeOf] = [endYear + range.to];
		} else if (range.from === null && range.to === null) {
			updatedFacets.facets = {};
		}

		const newSearchState = {
			...searchState,
			...updatedFacets,
			p: 1,
		};

		setSearchState(newSearchState);

		/** logic to calculate the total hits and checking if there are multiple facet type got checked  */
		let totalFilteredHits = 0;
		let selectedFacetModelsCount = 0;

		for (const facet of updatedFacetModels) {
			let hasSelectedOption = false;

			if (facet.key === 'ReleaseDate' && facet.facetType === 'dateSelect') {
				// Handle date facetOptions
				for (const option of facet.facetOptions) {
					if (option.timeInterval) {
						// Check if any of the selectable years are selected
						for (const yearOption of option.timeInterval.selectableYears) {
							if (yearOption.selected) {
								hasSelectedOption = true;
								break; // Since only one year can be selected, break after finding the first selected one
							}
						}
					}
				}
			} else {
				// Handle other regular facetOptions
				for (const option of facet.facetOptions) {
					if (
						updatedFacets.facets[facet.key] &&
						updatedFacets.facets[facet.key].includes(option.key) &&
						option.selected
					) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true; // Mark that this facet model has a selected option
					}
				}
			}

			if (hasSelectedOption) {
				selectedFacetModelsCount++; // Increment the count if any option in this facet is selected
			}
		}

		// If more than one facet model has selected options, we will call the api to get hits by passing "hitsonly" value true
		if (selectedFacetModelsCount > 1) {
			executeSearch(newSearchState, '', true);
		} else {
			// use normal calulcated hit count
			setMobileFilteredItemsCount(
				selectedFacetModelsCount == 0
					? searchModel?.numberOfHits
					: totalFilteredHits + totalYearHits
			);
		}
	};
	const handleCheckboxChange = (checked: boolean, filterTypeOf: string) => {
		const updatedFacets: UpdatedFacets = {
			facets: { ...searchState.facets },
		};

		// Check if the key exists
		if (checked) {
			// If checked, add or update the facet with the boolean as a string
			updatedFacets.facets[filterTypeOf] = [checked.toString()];
		} else {
			// If unchecked, remove only the specific facet
			delete updatedFacets.facets[filterTypeOf];
		}

		/**below we update stage facet, sort object and total filtered count with updates  */

		// Clone the current facets model to update it
		const updatedFacetModels = stageFacetModels.map((facet: FacetModel) => ({
			...facet,
			facetOptions: facet.facetOptions.map((option: FacetOption) => ({
				...option,
				selected: facet.key === filterTypeOf ? checked : option.selected,
			})),
		}));
		setstageFacetModels(updatedFacetModels);

		const newSearchState = {
			...searchState,
			...updatedFacets,
			p: 1,
		};
		setSearchState(newSearchState);

		/** logic to calculate the total hits and checking if there are multiple facet type got checked  */
		let totalFilteredHits = 0;
		let selectedFacetModelsCount = 0;

		for (const facet of updatedFacetModels) {
			let hasSelectedOption = false;

			if (facet.key === 'ReleaseDate' && facet.facetType === 'dateSelect') {
				// Handle date facetOptions
				for (const option of facet.facetOptions) {
					if (option.timeInterval) {
						// Check if any of the selectable years are selected
						for (const yearOption of option.timeInterval.selectableYears) {
							if (yearOption.selected) {
								hasSelectedOption = true;
								break; // Since only one year can be selected, break after finding the first selected one
							}
						}
					} else if (
						updatedFacets.facets[facet.key] &&
						updatedFacets.facets[facet.key].includes(option.key) &&
						option.selected
					) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true;
						break; // Only one option can be selected, so break after finding the first selected one
					}
				}
			} else if (facet.key === 'IsOfficialStatistics') {
				// Handle other regular facetOptions
				for (const option of facet.facetOptions) {
					if (updatedFacets.facets[facet.key] && option.selected) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true; // Marking  this facet model has a selected option
					}
				}
			} else {
				// Handle other regular facetOptions
				for (const option of facet.facetOptions) {
					if (
						updatedFacets.facets[facet.key] &&
						updatedFacets.facets[facet.key].includes(option.key) &&
						option.selected
					) {
						totalFilteredHits += option.hits;
						hasSelectedOption = true; // Marking  this facet model has a selected option
					}
				}
			}

			if (hasSelectedOption) {
				selectedFacetModelsCount++; // Increment the count if any option in this facet is selected
			}
		}

		// If more than one facet model has selected options, we will call the api to get hits by passing "hitsonly" value true
		if (selectedFacetModelsCount > 1) {
			executeSearch(newSearchState, '', true);
		} else {
			// use normal calulcated hit count
			setMobileFilteredItemsCount(
				selectedFacetModelsCount == 0
					? searchModel?.numberOfHits
					: totalFilteredHits + mobileYearItemsCount
			);
		}
	};

	/**filter submit function for mobile */
	const onMobileSubmit = () => {
		executeSearch(searchState);
		if (showModal) closeModal();
	};

	/**autocomplete submit function */
	const handleAutocompleteSubmit = (suggestion: string) => {
		if (suggestion.length > 0) {
			const params = {
				...searchState,
				q: suggestion,
				p: 1,
			};
			setSearchState(params);
			executeSearch(params);
			setUserQuery(suggestion);
		}
	};

	/**filter clear all function */
	const mobileClearAll = () => {
		resetFilters();
	};

	const executeSearch = (
		params: queryParameters,
		currentURL: string = '',
		hitsOnly: boolean = false
	) => {
		const queryParams = {} as queryParameters;
		const searchURL = {} as queryParameters;

		queryParams.p = params.p;
		searchURL.p = params.p && params.p > 1 ? params.p : null;
		queryParams.s = params.s ? params.s : 12;
		queryParams.id = params.id;
		queryParams.lang = params.lang;

		if (params.q) {
			queryParams.q = params.q.trimStart();
			searchURL.q = queryParams.q;
		}

		/* special treatment for facets */
		const facetsArray = Object.entries(params.facets);

		const encodesFilters = facetsArray
			.map(([key, values]) => {
				if (values.length > 0) {
					const formattedValues = values.map((value) =>
						encodeURIComponent(value)
					);
					return `${key}:${formattedValues.join(':')}`;
				}
				return null;
			})
			.filter((filter) => filter !== null) // Remove null values
			.join(';');

		const filtersURL = encodesFilters !== '' ? `facets=${encodesFilters}` : '';

		/* build URL */
		const queryFormated = buildQueryString(searchURL);

		let queryURL = '';
		if (filtersURL.length > 0) {
			queryURL += filtersURL;
		}
		if (queryFormated.length > 0) {
			queryURL += (queryURL.length > 0 ? '&' : '') + queryFormated;
		}

		let URL = currentURL.length > 0 && currentURL !== url ? currentURL : url;

		/* fetch results */
		const queryParamsURL =
			filtersURL.length > 0
				? filtersURL + '&' + buildQueryString(queryParams)
				: buildQueryString(queryParams);

		/**If hits only flag is true we will execute the api to get total hits otherwise we will execute the search and get results*/
		if (hitsOnly) {
			const hitsOnlyURL = queryParamsURL && `${queryParamsURL}&hitsonly=true`;
			getEpiSearchResult('/', searchApi, hitsOnlyURL)
				.then((res) => {
					setMobileFilteredItemsCount(res?.searchModel?.numberOfHits);
				})
				.catch((error) => {
					console.error('Error fetching hits only data:', error);
				});
		} else {
			history.push({ pathname: URL, search: queryURL });
			setShowAutocomplete(false);
			dispatch(
				fetchSearchResult(
					modelType,
					rootPageId,
					'searchResponse',
					'/',
					searchApi,
					queryParamsURL
				)
			);
		}
	};

	/** debounce function to execute search after 350ms */
	const debouncedExecuteSearch = useCallback(
		debounce((params: queryParameters, currentURL: string) => {
			executeSearch(params, currentURL);
		}, 350),
		[]
	);
	/* 

	const debouncedSetPillItems = debounce(
		(updateFunction: (prevPillItems: any[]) => any[]) => {
			setPillItems(updateFunction);
		},
		150
	); */
	const getFacetsFromUrl = () => {
		let urlParams = new URLSearchParams(history.location.search);
		let facets: any = urlParams.get('facets');

		let splitedFacets = facets?.split(';') || [];
		let ff: { [key: string]: string[] } = {};

		splitedFacets.forEach((facet: string) => {
			const [key, ...values] = facet.split(':');
			const decodedValues = values.map((value) => decodeURIComponent(value));

			if (!ff[key]) {
				ff[key] = [];
			}
			ff[key] = ff[key].concat(decodedValues);
		});

		return ff;
	};
	return (
		<SearchOverlappBlock>
			<form onSubmit={handleSubmit} role="search">
				{/* SEARCH */}
				<label className="as-h3" htmlFor="userListingInputQuery">
					{searchTopic}
				</label>
				<SearchInputWrapper>
					<div className="autocomplete-section">
						<input
							type="text"
							id="userListingInputQuery"
							placeholder={searchPlaceholder ?? ''}
							aria-label={searchActionText}
							maxLength={200}
							value={userQuery}
							ref={searchRef}
							onChange={(event) => handleUserQuery(event)}
							autoComplete="off"
							enterKeyHint="search"
						/>
						{showAutocomplete && (
							<Autocomplete
								query={userQuery}
								onSubmit={handleAutocompleteSubmit}
								currentState={searchState}
								autoCompleteApi={autoCompleteApi}
							/>
						)}
					</div>
					{userQuery.length >= 1 && (
						<div
							className="search-close-icon"
							onClick={resetSearch}
							tabIndex={0}
						>
							<IconCross
								width="24px"
								height="24px"
								aria-label="clean input field"
							/>
						</div>
					)}
					{!isNarrowDisplay && (
						<>
							<Button aria-label={searchActionText} type="submit">
								{searchActionText}
							</Button>
						</>
					)}
				</SearchInputWrapper>
				{query && (
					<>
						{searchResultLabel?.length && searchModel.numberOfHits > 0 ? (
							<ResultMessageHelper>
								<span>
									{searchResultLabel.split('{0}').map((part, index) => {
										return (
											<React.Fragment key={index}>
												{part}
												{index > 0 && <StyledSpan>"{query}"</StyledSpan>}
											</React.Fragment>
										);
									})}
								</span>
							</ResultMessageHelper>
						) : (
							!filterEnabled && (
								<ResultMessageHelper>
									<span className="span-flex">
										<span>
											{searchResultLabelNoMatches.replace('{0}', query)}
										</span>
										<span className="as-label">
											{searchResultLabelNoMatchesHint}
										</span>
									</span>
								</ResultMessageHelper>
							)
						)}
					</>
				)}
			</form>
			<div>
				{/* FILTERS */}
				<label className="as-h4" htmlFor="userListingFilter">
					{filterLabel}
				</label>
				{/**this button only visible in mobile */}
				<AllFilterButton
					onClick={openModal}
					iconName="menu"
					variant={ButtonVariant.Tertiary}
				>
					{allFilterText}
				</AllFilterButton>

				{pillItem && pillItem.length > 0 && (
					<PillsBlock>
						{pillItem.length > 0 &&
							pillItem.map((pill, index) => {
								return (
									<Pills
										key={index}
										items={[pill]}
										onRemove={() => onPillRemove(pill, true)}
									/>
								);
							})}
						{pillItem.length > 1 && (
							<PillResetButton onClick={resetFilters}>
								<span>{clearAllFiltersText}</span>
							</PillResetButton>
						)}
					</PillsBlock>
				)}
			</div>
			{/**all filter modal for mobile */}
			<FilterModal
				onClose={closeModal}
				onMobileSubmit={onMobileSubmit}
				onClear={mobileClearAll}
				filteredCount={mobileFilteredItemsCount}
				clearAllText={clearAllFiltersText}
				filterLabel={filterLabel}
				filterExist={filterExist}
				showFilterModal={showModal}
			>
				<AccordionFilter
					facetModels={stageFacetModels}
					onChange={onMobileFilterChanged}
					facets={searchState.facets}
					onDateSelectChanged={onDateSelectChanged}
					selectedDates={selectedDates}
					onCheckboxChanged={handleCheckboxChange}
				/>
			</FilterModal>
		</SearchOverlappBlock>
	);
};

export default SearchByListngPageMobile;
