/**
 * FilterDropdown - styles
 */

import {
	styleForButton,
	styleForLabel,
	styleForSelect,
} from 'components/Typography';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

type IsRegulationPageProps = {
	isRegulationPage?: boolean;
};

export const FilterButtonContainer = styled.div<IsRegulationPageProps>`
	display: flex;
	align-items: center;
	margin-top: ${(props) => (props.isRegulationPage ? '2.5' : '0.75')}rem;
	margin-bottom: ${(props) => (props.isRegulationPage ? '2' : '0')}rem;
	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
		margin-top: ${(props) => props.isRegulationPage && '1.5'}rem;
	}
`;

export const FilterBlock = styled.div`
	position: relative;
`;

export const CheckboxWrapper = styled.div<IsRegulationPageProps>`
	margin-top: ${(props) => !props.isRegulationPage && '-30px'};
`;

export const LabelButtonWrapper = styled.div<IsRegulationPageProps>`
	margin-right: ${(props) =>
		props.isRegulationPage ? props.theme.spacing.space3 : '0.75'}rem;
	${mediaQueries.phone} {
		margin-bottom: 1.5rem;
		margin-right: 0;
	}
`;

type SelectFilterContainerProps = {
	isOpen?: boolean;
};

export const FilterButton = styled.button<SelectFilterContainerProps>`
	${styleForSelect}	
	padding: 7px 9px;	
	cursor: pointer;
	display flex;
	height: 40px;
	&:hover {
		transition: background-color 100ms ease;
		background-color: ${(props) => props.theme.colors.blue2}a0;
		border: 1px solid ${(props) => props.theme.colors.primary};
	}
	
	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
		align-self: center;
		flex: 0 0 auto;
		margin-left: 10px;
		pointer-events: none;
	}

	.unselectable {
    	pointer-events: none;
	}
	
`;

type FilterKey = {
	filterKey: string;
};

type FilterButtonProps = SelectFilterContainerProps & FilterKey;

export const FilterRegPageButton = styled.button<FilterButtonProps>`
	${styleForSelect}
	padding: 7px 9px;
	cursor: pointer;
	display flex;
	width: ${(props) => (props.filterKey === 'RevokedDate' ? '115px' : '300px')};
	height: 40px;
	justify-content: space-between;
	&:hover {
		transition: background-color 100ms ease;
		background-color: ${(props) => props.theme.colors.blue2}a0;
		border: 1px solid ${(props) => props.theme.colors.primary};
	}

	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
		align-self: center;
		flex: 0 0 auto;
		margin-left: 10px;
		pointer-events: none;
	}

	.unselectable {
		pointer-events: none;
	}

	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const FilterOptionsContainer = styled.fieldset`
	position: absolute;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	min-width: 300px;
	max-height: 397px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 100;
	border: transparent;
	border-radius: 8px;
	background-color: rgb(var(--colour-elevation-1, 255, 255, 255));
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);

	legend {
		position: absolute !important;
		display: block;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		white-space: nowrap;
		visibility: visible;
		border: 0;
	}
`;

export const TimeIntervalDropdown = styled.div`
	display: flex;
	flex-direction: column;
	padding: 6px 12px;
	width: 415px;
	.separator {
		border-top: 1px solid #bfbfbf;
		width: 95%;
		margin-bottom: 16px;
	}
	${styleForButton}
	${mediaQueries.phone} {
		width: auto;
		.separator {
			width: 100%;
		}
	}
`;

export const CheckboxesInline = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	align-items: center;

	span {
		${mediaQueries.phone} {
			margin-top: 2px;
		}
	}
`;
