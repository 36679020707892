/**
 * Radio - styles
 */

import styled from 'styled-components';
import { accessibilityFocus } from 'theme/styles';

export const Wrap = styled.div`
	${(props) => props.theme.typography.style8.getStyle()};
`;

export const Icon = styled.svg`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	margin-top: 2px;
	margin-right: 10px;
`;

export const RadioOption = styled.li`
	cursor: pointer;
	display: inline-block;
	margin-right: 40px;

	&:focus {
		${accessibilityFocus()};
	}
	&:hover {
		svg circle {
			stroke-width: 3px;
		}
	}
	span {
		${(props) => props.theme.typography.style8.getStyle()};
	}
	svg {
		vertical-align: top;
	}
`;

export const Container = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	& > ${RadioOption}:last-child {
		margin-right: 0px;
	}
`;
